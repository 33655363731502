#footer {
  background: #050608;
  padding: 80px 0;
}
.foot-menu li {
  display: inline-block;
  margin-bottom: 5px;
}
.foot-menu li a {
  position: relative;
  padding-right: 10px;
  color: #fff;
  margin-right: 5px;
}
.foot-menu li a:after {
  content: "";
  background: #ccc;
  height: 15px;
  width: 1px;
  right: 0;
  top: 4px;
  position: absolute;
}
.foot-menu li:last-child a:after {
  background: none;
}
.border-bottom.border-grey {
  border-bottom: 1px solid #484848 !important;
}
.foot-menu {
  max-width: 567px;
}
