.accordion .accordion-button:not(.collapsed), 
.accordion .accordion-button {
    background: none !important;
    box-shadow: none !important;
    padding-left: 0;
    padding-right: 0;
    font-weight: 600;
    color: #000;
}
.accordion .accordion-item {
    border: none !important;
}
.accordion-body label {
    display: flex;
}