.top_filter_menu li {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    position: relative;
}
.top_filter_menu li:before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    left: 0;
    top: 19px;
}
.top_filter_item li:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 40px;
    clip-path: polygon(100% 0, 100% 100%, 50% 50%, 0 100%, 0 0, 50% 0);
}
.top_filter_item li {
    width: 50%;
    padding: 5px;
    float: left;
    position: relative;
    margin-bottom: 10px;
}
.top_filter_item img {
    display: block;
    width: 100%;
    height: 100%;
}
.top_filter_menu, 
.top_filter_item {
    list-style: none;
    padding: 0;
}
.site_pagination {
    justify-content: center;
    align-items: center;
}
@media screen and (min-width: 1280px) {
    .top_filter_item li.active, .top_filter_item li {
        width: 33.33%;
    }
}

@media screen and (min-width: 768px) {
    .top_filter_item li.active, .top_filter_item li {
        width: 33.33%;
    }
}

