#header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 30px;
  z-index: 999;
  background: transparent;
}
.menu_fixed {
  top: 0;
  z-index: 1056;
  position: absolute;
  background: rgb(0 0 0 / 60%);
}
.navbar-brand {
  max-width: 80px;
}
/* .stellarnav .menu-toggle {
  position: absolute;
  right: 0;
  top: 25px;
  width: 30px;
  padding: 0 !important;
  height: 18px;
  margin: 0 auto;
} */
.navbar-nav .nav-link {
  font-family: "IBM Plex Sans", sans-serif !important;
}
.img-full {
  width: 100%;
}
/* .scrollTop {
  position: fixed;
  right: 15px;
  bottom: 40px;
  display: none;
  z-index: 999;
  border-radius: 50px;
  cursor: pointer;
}
.scrollTop a {
  color: #fff;
} */
/* .navbar-expand-lg .navbar-collapse {
  display: block !important;
} */
.cartbtn small {
  position: absolute;
  right: 12px;
  top: -2px;
  width: 6px;
  text-align: center;
  height: 6px;
  line-height: 17px;
}
.dropdown-toggle::after {
  display: none;
}
#header .navbar-nav .nav-link,
.searchBtn {
  color: #fff;
  padding-top: 0;
}
.searchBtn i {
  font-size: 22px;
}
.searchBtn i {
  top: 2px;
  left: 0;
  position: absolute;
  transform: rotate(270deg);
}
/* lang-block */
#langLabel {
  min-width: 75px;
  height: 32px;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  text-align: left;
  padding: 0 15px;
  letter-spacing: 0.7px;
  margin-top: 0;
}
.lang-block .form-select {
  font-weight: 600;
  background-size: 15px !important;
  background-repeat: no-repeat;
  background-position: 52px 8px;
  padding: 2px 2rem 0.375rem 0.75rem;
  background-image: url("../header/assets/arrow_down_light.png");
  background-repeat: no-repeat;
}
.cartbx .dropdown-menu {
  right: 0;
}
.cartbx.dropdown:after {
  content: "";
  background: #c1c8c0;
  top: 8px;
  right: 0;
  height: 15px;
  width: 1px;
  position: absolute;
}
#header {
  font-weight: 600;
}
/* lang block */

@media screen and (max-width: 767px) {
  #header {
    top: 0;
    background: #ffca0a;
  }
  .px-5.mob-px-2 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
}
