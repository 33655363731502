/* animated play button */
.strokecircle {
    stroke: #fff;
    stroke-dasharray: 650;
    stroke-dashoffset: 650;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    opacity: 0.3;
  }
  .btn-stroke {
    display: inline-block;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
  }
  .btn-stroke .triangle {
    transition: all 0.7s ease-in-out;
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: #fff;
    transform: translateY(0);
  }
  .btn-stroke:hover .triangle {
    stroke-dashoffset: 0;
    opacity: 1;
    stroke: #fff;
    animation: nudge 0.7s ease-in-out;
  }
  @keyframes nudge {
    0% {
      transform: translateX(0);
    }
    30% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    70% {
      transform: translateX(-2px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .btn-stroke:hover .strokecircle {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  /* animated play button */
  /* Feel free to change duration  */
  .animated {
    -webkit-animation-duration: 1000 ms;
    animation-duration: 1000 ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }