.event-list-item .fig-caption {
  bottom: 0;
  width: 100%;
  padding: 15px 30px;
}
.event-list-item .fig-overlay .top-txt {
  top: 0;
  z-index: 2;
  position: absolute;
  padding: 15px 30px;
}
.event-list-item .fig-overlay:hover .fig-caption {
  bottom: 10px;
}
.event-list-item .fig-overlay:hover .top-txt {
  top: 10px;
}
.event-list-item .digit-wrapper::after {
  display: none;
}
