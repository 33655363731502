/* home slider */
.video-block .desc {
    position: absolute;
    right: 120px;
    bottom: 100px;
    z-index: 9;
    max-width: 415px;
}
.video-block .desc h3 {
    font-size: 26px;
}
.sliderContainer {
    position: relative;
    height: 100vh;
  }
  .sliderContainer .slick-slider {
    height: 100%;
  }
  .slider.video-block {
    width: 100%;
    margin: 0;
    height: 100%;
  }
  .slider.video-block .slick-slide {
    position: relative;
  }
  .slick-list {
    height: 100%;
  }
  .slick-slide {
    color: white;
    padding: 0;
    font-size: 30px;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
  .slick-dots {
    bottom: -30px;
  }
  .slide-nav-wrap {
    position: absolute;
    bottom: 30px;
    max-width: 320px;
    right: 220px;
    display: inline-flex;
    text-align: center;
  }
  .progressBarContainer div.progressLine {
    display: block;
    width: 100%;
    padding: 0;
    cursor: pointer;
    margin-right: 0;
    float: left;
    color: white;
  }
  .progressBarContainer div.progressLine:last-child {
    padding: 0 5px;
  }
  .progressBarContainer div.progressLine span.progressBar {
    width: 100%;
    height: 1px;
    margin-bottom: 5px;
    background-color: rgba(255, 255, 255, 0.4);
    display: block;
  }
  .progressBarContainer div span.progressBar .inProgress {
    width: 0%;
    height: 1px;
  }
  
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    height: 100%;
  }
  
  /* home slider */
  
  .slick-slide.slick-current.slick-active .progressLine h6 {
    color: #fff;
  }
  .slick-slide .progressLine h6 {
    color: #d4d4d4;
  }
  /* play-btn-group */
  .play-btn-group {
    position: absolute;
    right: 30px;
    bottom: 21px;
  }
  .play-btn-group i {
    font-size: 22px;
    border-radius: 30px;
    border: 2px solid #a2aca3;
    padding: 8px;
  }
  
  .play-btn-group .btn-pause i {
    color: #fff;
  }
  .show {
    display: block;
  }
  .hide {
    display: none;
  }
  /* play-btn-group */
  .descwrap {
    padding-left: 15px;
  }
  .descwrap:before {
    content: "";
    background: #a8b6a5;
    left: 0;
    top: 7px;
    width: 1px;
    position: absolute;
    height: 144px;
  }
  /* progress bar */
  .slider.video-block .slick-slide section,
  .slider.video-block .slider_item {
    height: 100vh;
  }
  .mid-sec {
    position: absolute;
    top: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.head-span1 {
  padding-left: 65px;
}
.head-span1:before {
  content: "";
  left: 0;
  top: 22px;
  height: 1px;
  width: 60px;
  position: absolute;
  background: #c1c8c0;
}
.head-span2:before, 
.head-span2:after {
  content: "";
  top: 22px;
  height: 43px;
  width: 1px;
  position: absolute;
  background: #c1c8c0;
}
.head-span2:before {
  left: 0;
}
.head-span2:after {
  right: 0;
}
  @media screen and (max-width: 767px) { 
    .video-block video {
      height: 100vh;
    }
    .video-block .desc {
      position: absolute;
      right: 0;
      bottom: 120px;
      z-index: 9;
      max-width: 100%;
      text-align: center;
      padding: 0 15px;
    }
    .descwrap {
      padding-left: 0;
    }

  }