.reg-slide-block .desc-default {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 567px;
  padding: 50px 80px;
  background: #fff;
}

.reg-slide-block .owl-carousel.owl-theme .owl-nav button.owl-prev, 
.reg-slide-block .owl-carousel.owl-theme .owl-nav button.owl-next {
    position: absolute;
    bottom: 0;
    width: 80px;
    height: 80px;
    opacity: 0.9 !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
}

#block-reg.reg-slide-block .owl-theme .owl-nav .owl-next {
    left: 567px;
    z-index: 9;
}

#block-reg.reg-slide-block .owl-theme .owl-nav .owl-prev {
    left: 487px;
    z-index: 9;
}