/* .customSelect .customLabel {
    left: -15px !important;
} */
.newsletterForm .css-1480iag-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before,
.newsletterForm .css-ghsjzk-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before,
.newsletterForm .css-ghsjzk-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 1px solid #ffcb0d !important;
}
.newsletterForm .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.newsletterForm .css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #7e7e7e;
    font-family: "IBM Plex Sans", sans-serif !important;
}
.newsletterForm .css-1480iag-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 1px solid #ffcb0d;
}
.newsletterForm .css-1480iag-MuiInputBase-root-MuiInput-root,
.newsletterForm .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root,
.newsletterForm .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.newsletterForm .customLabel {
    font-family: "IBM Plex Sans", sans-serif !important;
}