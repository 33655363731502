.tour-guide {
    border: 0.3rem solid rgba(#fff, 0.3);
    margin-top: -6rem;
    margin-bottom: 1rem;
    max-width: 9rem;
}
.card .card-body {
    font-size: 14px;
    color: #424242;
    padding: 20px;
    font-weight: 400;
}