.desc-esntl {
    position: absolute;
    padding: 60px;
    bottom: 0;
}

.col-overlay:hover {
    background: rgba(0, 0, 0, 0.253);
}

.reg-slide-block .owl-carousel.owl-theme .owl-nav button.owl-prev,
.reg-slide-block .owl-carousel.owl-theme .owl-nav button.owl-next {
    position: absolute;
    bottom: 0;
    width: 80px;
    height: 80px;
    opacity: 0.9 !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
}

.reg-slide-block .owl-carousel.owl-theme .owl-nav button.owl-next {
    right: 0;
}

.reg-slide-block .owl-carousel.owl-theme .owl-nav button.owl-prev {
    right: 80px;
}

.section-block .owl-theme .owl-dots {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
}

.section-block .owl-theme .owl-nav {
    margin-top: 0;
}

.section-block .owl-theme .owl-dots button.owl-dot.active span {
    background: #ffcb0d !important;
}

.section-block .owl-theme .owl-dots button.owl-dot.active span {
    width: 22px;
    height: 6px;
    border-radius: 30px;
}

.section-block .owl-theme .owl-dots button.owl-dot span {
    background: #7f7f7f !important;
    width: 6px;
    height: 6px;
    border-radius: 6px;
}