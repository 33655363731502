.grid {
    position: relative;
  }
  .grid figure {
    margin-bottom: 28px;
  }
  .item-widget {
    margin: 0;
  }
  .fig-caption {
    position: absolute;
    bottom: 62px;
    z-index: 99;
    width: 100%;
    padding: 30px 50px;
  }
  .fig-overlay {
    overflow: hidden;
  }
  .fig-overlay:hover .img-zoom {
    transform: scale(1.1);
  }
  .fig-overlay:hover .fig-caption {
    bottom: 80px;
  }
  /* overlay */
  .fig-overlay .icon-fav {
    top: 30px;
    right: 30px;
  }
  .fig-overlay .icon-fav img,
  .owl-carousel .fig-overlay .icon-fav img {
    width: 36px;
  }
  .fig-overlay .icon-map {
    right: 0;
    left: 0;
    top: 60px;
    z-index: 1;
    margin: 0 auto;
    text-align: center;
    max-width: 160px;
  }
  .fig-overlay .icon-map img {
    width: 160px;
  }
  