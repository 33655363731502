.social-wdget {
  position: relative;
}

.icon-social {
  position: absolute;
  right: 30px;
  top: 30px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  text-align: center;
  border-radius: 30px;
  border: 1px solid #fff;
}
